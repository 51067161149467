import { throttle } from "lodash";
import { observer } from "mobx-react";
import { transparentize } from "polished";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { depths } from "@shared/styles";
import Flex from "~/components/Flex";
import { ClosedLockIcon, CloseIcon, MenuIcon, OpenLockIcon } from "~/components/Icons";
import NudeButton from "~/components/NudeButton";
import Tooltip from "~/components/Tooltip";
import useEventListener from "~/hooks/useEventListener";
import useMobile from "~/hooks/useMobile";
import useStores from "~/hooks/useStores";
import { LockIconState } from "~/types";
import { supportsPassiveListener } from "~/utils/browser";
import CenteredTitle from "./CenteredTitle";
import useShareId from "@database/hooks/useShareId";
import Button from "@uikit/Button";

type Props = {
  title: ReactNode;
  onChangeTitle?: (value: string) => void;
  breadcrumb?: ReactNode;
  actions?: ReactNode;
  hasSidebar?: boolean;
  inModal?: boolean;
  hasShadow?: boolean;
  alwaysShow?: boolean;
  titleString?: string;
  readOnly?: boolean;
  closeModal?: () => void;
  lockIconState?: LockIconState;
  handleLock?: (nextLock: boolean) => void;
};

function Header({
  breadcrumb,
  title,
  actions,
  hasSidebar,
  inModal,
  hasShadow,
  alwaysShow,
  titleString,
  onChangeTitle,
  readOnly,
  closeModal,
  lockIconState,
  handleLock
}: Props) {
  const { ui } = useStores();
  const { t } = useTranslation();
  const isMobile = useMobile();
  const shareId = useShareId();

  const hasMobileSidebar = hasSidebar && isMobile;

  const passThrough = !actions && !breadcrumb && !title;

  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = useMemo(
    () => throttle(() => setScrolled(window.scrollY > 75), 50),
    []
  );

  const lockTooltip = useMemo(() => {
    return {
      [LockIconState.HIDDEN]: "",
      [LockIconState.LOCK]: t("Editing is blocked"),
      [LockIconState.UNLOCK]: t("Editing unlocked"),
    }[lockIconState || LockIconState.HIDDEN];
  }, [lockIconState, t]);

  useEventListener("scroll", handleScroll, window, supportsPassiveListener
    ? { passive: true }
    : { capture: false }
  );

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Wrapper
      $passThrough={passThrough}
      inModal={inModal}
      hasShadow={hasShadow}
      shrink={false}
      align="center"
      className="document-header"
    >
      {inModal && (
        <>
          <ModalCloseButton onClick={closeModal}>
            <CloseIcon color="currentColor" />
          </ModalCloseButton>
          {!shareId && <Separator />}
        </>
      )}
      {breadcrumb || hasMobileSidebar ? (
        <Breadcrumbs>
          {hasMobileSidebar && (
            <MobileMenuButton
              onClick={ui.toggleMobileSidebar}
              icon={<MenuIcon color="currentColor"/>}
              className="mobile-menu-button"
              mode="neutral"
            />
          )}
          {breadcrumb && isMobile && <Separator className="separator"/>}
          {breadcrumb}
          {lockIconState !== undefined && lockIconState !== LockIconState.HIDDEN && (
            <Tooltip
              tooltip={lockTooltip}
              placement="bottom"
              delay={250}
            >
              <LockButton
                onClick={() => handleLock?.(lockIconState !== LockIconState.LOCK)}
                data-testid="documentLockButton"
                mode="flat"
              >
                {lockIconState === LockIconState.LOCK && <ClosedLockIcon />}
                {lockIconState === LockIconState.UNLOCK && <OpenLockIcon />}
              </LockButton>
            </Tooltip>
          )}
        </Breadcrumbs>
      ) : null}
      <CenteredTitle
        title={title}
        titleString={titleString}
        alwaysShow={alwaysShow}
        handleScroll={scrollToTop}
        isScrolled={isScrolled}
        onSaveTitle={onChangeTitle}
        readOnly={readOnly}
      />
      <Actions align="center" justify="flex-end">
        {actions}
      </Actions>
    </Wrapper>
  );
}

const ModalCloseButton = styled(NudeButton)`
  margin-right: 5px;
`;

const Breadcrumbs = styled("div")`
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  padding-right: 6px;
  display: flex;
`;

const Actions = styled(Flex)`
  flex-grow: 1;
  flex-basis: 0;
  min-width: auto;
  padding-left: 8px;

  ${breakpoint("tablet")`
    position: unset;
  `};
`;

const Wrapper = styled(Flex)<{
  $passThrough?: boolean;
  inModal?: boolean;
  hasShadow?: boolean;
}>`
  ${({ hasShadow, theme }) => hasShadow && css`
    box-shadow: ${theme.headerShadow};
  `}
  
  top: 0;
  z-index: ${depths.header};
  position: sticky;
  background: ${({ theme }) => theme.background};

  ${({ $passThrough, theme }) =>
    $passThrough
      ? `
        background: transparent;
        pointer-events: none;
      `
      : `
        background: ${transparentize(0.2, theme.background)};
        backdrop-filter: blur(20px);
      `};

  padding: 10px 12px;
  transition: all 100ms ease-out;
  transform: translate3d(0, 0, 0);
  min-height: 44px;

  @supports (backdrop-filter: blur(20px)) {
    backdrop-filter: blur(20px);
    background: ${({ theme }) => transparentize(0.2, theme.background)};
  }

  @media print {
    display: none;
  }

  ${breakpoint("tablet")`
    padding: ${(props: { $passThrough?: boolean, inModal?: boolean }) => props.inModal ? "10px 32px" : "10px 16px"};
    justify-content: center;
  `};
`;

const MobileMenuButton = styled(Button)`
  background: transparent;
  margin-right: 6px;
  border: 0;
  pointer-events: auto;

  svg {
    fill: ${({ theme }) => theme.textTertiary}
  }
  @media print {
    display: none;
  }
`;

const LockButton = styled(Button)`
  width: 32px;
  
  margin-left: 10px;
  padding: 0;
  
  svg {
    margin: 0;
  }
`;

export const Separator = styled.div`
  flex-shrink: 0;
  margin-right: 6px;
  width: 1px;
  height: 28px;
  background: ${({ theme }) => theme.divider};
`;

export default observer(Header);
