// Типы для property в документе с типом database

import { TRelationOption } from "@database/properties/RelationProperty/RelationProperty";
import { PropertyModel } from "@database/stores";
import { ViewConfigType } from "@database/stores/DatabaseView";

export enum PropertyTypes {
  Text = "text",
  Number = "number",
  Select = "select",
  Multiselect = "multi_select",
  Checkbox = "checkbox",
  Url = "url",
  Email = "email",
  PhoneNumber = "phone_number",
  Date = "date",
  Person = "person",
  People = "people",
  Files = "files",
  Formula = "formula",
  Relation = "relation",
  Rollup = "rollup",
  Status = "status",
}

export enum DisabledPropertyTypes {
  Formula = "formula",
  Rollup = "rollup",
}

export type TPropertyType = typeof PropertyTypes[keyof typeof PropertyTypes];

export type TSelectOption = {
  id: string;
  label: string;
  color: string;
}

export type TPeopleOption = {
  id: string;
  label: string;
  iconUrl: string | null;
  withoutOpacity: boolean;
}

export type TPropertyOption<T = undefined> = {
  id: string;
  label: string;
} & T;

export interface IPropertyModel<T> {
  v: number;
  id: string;
  type: TPropertyType;
  title: string;
  config: Record<string, any>;
  options: TPropertyOption<T>[];
  permissions?: TPropertyPermissions;
}

// View config
export type TRelationPropertyViewConfig = {
  shareLinkedDocuments?: boolean;
  showDocumentIcon?: boolean;
}

// Типы для значений property в документе с типом row

export type TURLPropertyValue = {
  url: string;
  title: string;
}

export type TFilePropertyValue = {
  id: string;
  fileName: string;
  fileType: string;
  downloadURL: string;
};

export type TDatePropertyValue = {
  v: number;
  from: Date | string;
  to: Date | string;
  shouldNotifyFrom: boolean;
  shouldNotifyTo: boolean;
}

export type TPropertyValue =
  | number
  | string
  | string[]
  | TDatePropertyValue
  | TURLPropertyValue
  | TFilePropertyValue[]
  | TRelationOption[]
  | undefined
  | null;

export type TRowPropertyValues = { [id: string]: TPropertyValue };

export type RelationPropertyConfigType = {
  relationLimit: "onePage" | "noLimit";
  showDocumentIcon: boolean;
  shareLinkedDocuments: boolean;
}

export type TRelationPropertyConfig = {
  property: PropertyModel;
  onChangeConfig: (value: Partial<RelationPropertyConfigType>) => void;
  onChangeViewConfig: (config: Partial<TRelationPropertyViewConfig>) => void;
  viewConfig: ViewConfigType;
}

// Типы для разрешений на property

export enum PermissionTypes {
  LOCK = "",
  R = "r",
  RWC = "rwc",
}

export type TPropertyPermissions = {
  default: PermissionTypes;
  users: { [id: string]: PermissionTypes };
  groups: { [id: string]: PermissionTypes };
}

// Типы для фильтрации

export enum FilterOperation {
  Between = "Between",
  Greater = "Greater",
  Less = "Less",
  Contains = "Contains",
  NotContains = "NotContains",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  IsNull = "IsNull",
  IsNotNull = "IsNotNull",
  IsEquals = "IsEquals",
  IsNotEqual = "IsNotEqual"
}

export type TFilterOperationTypes = keyof typeof FilterOperation;

export type TFilterValue = string | string[] | number[];

export interface TFilterElement {
  id?: string;
  title: string;
  filterValue: TFilterValue;
  filterOperation: TFilterOperationTypes;
  filterPropertyId: string;
  type: TPropertyType;
}

// Типы для сортировки

export type TSortDirections = "ASC" | "DESC";

export type TSortProperties = {
  orderBy?: string;
  direction?: TSortDirections;
  sortType?: TPropertyType;
  sortTitle?: string;
};