import i18next from "i18next";
import { isArray } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import type {
  TPropertyType,
  TPropertyPermissions,
  TPropertyOption,
  IPropertyModel,
  TPropertyValue
} from "@shared/types";
import { PropertyTypes } from "@shared/types";
import Document from "~/models/Document";
import DatabaseRow from "@database/stores/DatabaseRow";
import DatabaseStore from "@database/stores/DatabaseStore";

export const CURRENT_PROPERTY_VERSION = 1;

export type ViewConfigType = {
  width?: number;
  align?: "left" | "right" | "center";
  wordWrap?: "none" | "wrap";
};

export type ReverseRelationProp = {
  id?: string | null;
  title?: string;
}

export default class PropertyModel<T = unknown> implements IPropertyModel<T> {
  @observable
  id = uuidv4();

  @observable
  v = CURRENT_PROPERTY_VERSION;

  @observable
  title = "";

  @observable
  type: TPropertyType = PropertyTypes.Text;

  @observable
  options: TPropertyOption<T>[] = [];

  @observable.deep
  config: Record<string, unknown> = {};

  @observable
  permissions?: TPropertyPermissions;

  @observable
  relationDatabaseId?: string;

  @observable
  relationId?: string;

  @observable
  shouldNotifyFrom = false;

  @observable
  shouldNotifyTo = false;

  // параметр для проверки прав пользователя на просмотр документа
  // актуально только для relation property (не сохраняется на бек)
  @observable
  userHasRightsToRead?: boolean;

  @observable
  reverseRelationProp?: ReverseRelationProp;

  @action.bound
  toggleCalendarEvent(type: "to" | "from", has: boolean) {
    if (type === "to") {
      this.shouldNotifyTo = has;
    } else {
      this.shouldNotifyFrom = has;
    }
  }

  private database: DatabaseStore | undefined;

  get databaseId() {
    if (this.database && "database" in this.database) {
      return this.database.database.id
    }
    return null;
  }

  constructor(data?: Record<string, any>, database?: DatabaseStore) {
    if (data) {
      this.populateFromObject(data);
    }

    this.database = database;
    makeObservable(this);
  }

  setDatabase(database: DatabaseStore) {
    this.database = database;
  }

  get propertyDatabase() {
    if (this.database && "database" in this.database) {
      return this.database.database;
    }
    return null;
  }

  @computed
  get translationType() {
    const key = Object.keys(PropertyTypes).find(propertyType => PropertyTypes[propertyType] === this.type);
    if (!key) {
      return "";
    }

    return i18next.t(key);
  }

  @action.bound
  setTitle(title: string) {
    this.title = title;
  }

  @action.bound
  setType(type: TPropertyType) {
    this.type = type;
  }

  @action.bound
  setOptions(options: TPropertyOption<T>[]) {
    this.options = [...options];
  }

  @action.bound
  getOption(optionId: string) {
    return this.options.find((opt) => opt.id === optionId);
  }

  @action.bound
  updateOption(id: string, option: TPropertyOption<T>) {
    const index = this.options.findIndex((op) => op.id === id);
    if (index === -1) {
      return;
    }
    this.options.splice(index, 1, {
      ...this.options[index],
      ...option,
    });
  }

  @action.bound
  removeOption(id: string) {
    const index = this.options.findIndex((op) => op.id === id);
    if (index === -1) {
      return;
    }
    this.options = this.options.filter((_, i) => i !== index);
  }

  getPropertyValue(row: DatabaseRow | Document) {
    if (this.type === "select" || this.type === "status") {
      if (isArray(row.properties[this.id]) && (row.properties[this.id] as string[]).length) {
        return (row.properties[this.id] as string[])[0];
      } else {
        return "";
      }
    }
    return row.properties[this.id];
  }

  @action.bound
  setConfig(config: Record<string, any>) {
    this.config = { ...config };
  }

  @action.bound
  setPermissions(permissions: TPropertyPermissions | undefined) {
    this.permissions = permissions;
  }

  @action.bound
  changePropertyType(type: TPropertyType) {
    this.type = type;
    // Here may be migration code ...
  }

  @action.bound
  setUserHasRightsToRead(state: boolean) {
    this.userHasRightsToRead = state;
  }

  @action
  getValue(row: DatabaseRow, isTitle: boolean) {
    if (isTitle) {
      return row.title;
    }

    const value = row.properties[this.id] as TPropertyValue;

    // Интерпретируем отсутствие значения для чекбокса как неустановленный чекбокс
    if (value === undefined && this.type === "checkbox") {
      return "0";
    }

    return value;
  }

  @action.bound
  setReverseRelationProp(value?: ReverseRelationProp) {
    this.reverseRelationProp = value;
  }

  populateFromObject(data: Record<string, any>) {
    if (data.id) {
      this.id = data.id;
    }
    this.v = data.v;
    this.title = data.title || this.title;
    this.type = (data.type as TPropertyType) || this.type;
    this.options = data.options || this.options;
    this.config = data.config || this.config;
    this.permissions = data.permissions || this.permissions;
    this.relationDatabaseId = data.relationDatabaseId || this.relationDatabaseId;
    this.reverseRelationProp = data.reverseRelationProp || this.reverseRelationProp;
    this.relationId = data.relationId || this.relationId;
  }

  toJSON() {
    return {
      v: this.v,
      id: this.id,
      title: this.title,
      type: this.type,
      options: this.options,
      config: this.config,
      permissions: this.permissions,
      relationDatabaseId: this.relationDatabaseId,
      reverseRelationProp: this.reverseRelationProp,
      relationId: this.relationId,
    };
  }

  // FIXME: make this.saveOne as default saving method https://tasks.wilix.dev/issue/YNT-3506
  async save() {
    return this.database ? this.database.saveProperties() : undefined;
  }

  async saveOne(property: PropertyModel) {
    return this.database ? this.database.saveProperty(property) : undefined;
  }
}
