import { Mode } from "@shared/types/comments";

export enum ActionType {
  SET_HOVERED_COMMENT = "SET_HOVERED_COMMENT",
  CREATE_COMMENT = "CREATE_COMMENT",
  EDIT_COMMENT = "EDIT_COMMENT",
  REMOVE_COMMENT = "REMOVE_COMMENT",
  SET_COMMENT_ATTRS = "SET_COMMENT_ATTRS",
  SCROLL_COMMENTS_LIST_TO_DOWN = "SCROLL_COMMENTS_LIST_TO_DOWN",
  REFRESH_COMMENTS_LIST = "REFRESH_COMMENTS_LIST",
  MODAL_COMMENTS_BLOCK = "MODAL_COMMENTS_BLOCK",
  REFRESH_NESTED_DOCS = "REFRESH_NESTED_DOCS",
  CLOSE_ROW_DOCUMENT_MODAL = "CLOSE_ROW_DOCUMENT_MODAL",
  UPDATE_READONLY_FOR_INLINE_NODE = "UPDATE_READONLY_FOR_INLINE_NODE",
  OPEN_LINK_EDITOR_FOR_TOOLTIP = "OPEN_LINK_EDITOR_FOR_TOOLTIP",
  UPDATE_LINK_EDITOR_TEXT = "UPDATE_LINK_EDITOR_TEXT",
  OPEN_LINK_EDITOR_FOR_BLOCK_MENU = "OPEN_LINK_EDITOR_FOR_BLOCK_MENU",
}

export type SetHoveredComment = {
  action: ActionType.SET_HOVERED_COMMENT;
  hoveredComment: string | null;
}

export type CreateComment = {
  action: ActionType.CREATE_COMMENT;
  commentId: string;
}

export type EditComment = {
  action: ActionType.EDIT_COMMENT;
  commentId: string;
}

export type RemoveComment = {
  action: ActionType.REMOVE_COMMENT;
  commentId: string;
}

export type SetCommentAttrs = {
  action: ActionType.SET_COMMENT_ATTRS;
  commentId: string;
  attrs: any;
}

export type ScrollCommentsListToDown = {
  action: ActionType.SCROLL_COMMENTS_LIST_TO_DOWN;
  mode?: Mode;
}

export type RefreshCommentsList = {
  action: ActionType.REFRESH_COMMENTS_LIST;
}

export type ModalCommentBlock = {
  action: ActionType.MODAL_COMMENTS_BLOCK;
  isVisible: boolean;
}

export type RefreshNestedDocs = {
  action: ActionType.REFRESH_NESTED_DOCS;
}

export type CloseRowDocumentModal = {
  action: ActionType.CLOSE_ROW_DOCUMENT_MODAL;
  documentIdInModal: string;
}

export type UpdateReadonlyForInlineNode = {
  action: ActionType.UPDATE_READONLY_FOR_INLINE_NODE;
  readOnly: boolean;
  ids: string[];
}

export type OpenLinkEditor = {
  action: ActionType.OPEN_LINK_EDITOR_FOR_TOOLTIP;
  link: HTMLAnchorElement;
}

export type UpdateLinkEditorText = {
  action: ActionType.UPDATE_LINK_EDITOR_TEXT;
}

export type OpenLinkEditorForBlockMenu = {
  action: ActionType.OPEN_LINK_EDITOR_FOR_BLOCK_MENU;
  pos: number;
}

export type Action =
  | SetHoveredComment
  | CreateComment
  | EditComment
  | RemoveComment
  | SetCommentAttrs
  | ScrollCommentsListToDown
  | RefreshCommentsList
  | ModalCommentBlock
  | RefreshNestedDocs
  | CloseRowDocumentModal
  | OpenLinkEditor
  | UpdateLinkEditorText
  | OpenLinkEditorForBlockMenu
  | UpdateReadonlyForInlineNode;
