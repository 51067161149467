const depths = {
  header: 800,
  sidebar: 900,
  editorToolbar: 2001,
  hoverPreview: 2000,
  // Note: editor lightbox is z-index 999
  modalOverlay: 2000,
  modal: 3000,
  zoomedImage: 3001,
  menu: 4000,
  /* зайдет под сайдбар, но над всем остальным **/
  contextMenu: 899,
  toasts: 5000,
  popover: 9000,
  titleBarDivider: 10000,
  loadingIndicatorBar: 20000,
  commandBar: 30000,
  syncBlockOperationPlaceholder: 10000,
  drawer: 799,
  emojiDisclosure: 3000,
  editorHeadingActions: 920,
  multiselectEditOptions: 4050,
};

export default depths;
