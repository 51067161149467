import { Location } from "history";
import { FC, ReactNode, ReactElement, MouseEventHandler, CSSProperties, MouseEvent, SyntheticEvent } from "react";
import { TFunction } from "react-i18next";
import { DocumentType } from "@shared/types"
import RootStore from "~/stores/RootStore";
import Document from "~/models/Document";

type ClickWithEvent = (e?: MouseEvent | SyntheticEvent) => MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>

type ClickReturned = () => void | string;

type ClickReturnedFunction = (event?: SyntheticEvent<Element, Event>) => unknown;

export type MenuItemButtonClick = ClickWithEvent | ClickReturned | ClickReturnedFunction;

export type MenuItemButton = {
  type: "button";
  title: ReactNode;
  onClick: MenuItemButtonClick;
  dangerous?: boolean;
  visible?: boolean;
  selected?: boolean;
  selectedRight?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  isBeta?: boolean;
};

export type MenuItemWithChildren = {
  type: "submenu";
  title: ReactNode;
  visible?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  hover?: boolean;

  items: MenuItem[];
  icon?: ReactElement;
  isBeta?: boolean;
};

export type MenuSeparator = {
  type: "separator";
  visible?: boolean;
};

export type SmallMenuHeading = {
  type: "small_heading";
  title: string;
  visible?: boolean;
}

export type MenuHeading = {
  type: "heading";
  title: string;
  visible?: boolean;
};

export type MenuInternalLink = {
  type: "route";
  title: ReactNode;
  to: string;
  visible?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  isBeta?: boolean;
};

export type MenuExternalLink = {
  type: "link";
  title: ReactNode;
  href: string;
  visible?: boolean;
  selected?: boolean;
  disabled?: boolean;
  level?: number;
  icon?: ReactElement;
};

export type MenuItem =
  | MenuInternalLink
  | MenuItemButton
  | MenuExternalLink
  | MenuItemWithChildren
  | MenuSeparator
  | MenuHeading
  | SmallMenuHeading;

export type ActionContext = {
  isContextMenu: boolean;
  isCommandBar: boolean;
  isButton: boolean;
  inStarredSection?: boolean;
  activeCollectionId: string | undefined;
  activeDocumentId: string | undefined;
  currentUserId: string | undefined;
  currentTeamId: string | undefined;
  location: Location;
  stores: RootStore;
  event?: Event;
  t: TFunction;
};

export type Action = {
  type?: undefined;
  id: string;
  name: ((context: ActionContext) => string | ReactElement) | string;
  section: ((context: ActionContext) => string) | string;
  shortcut?: string[];
  keywords?: string;
  iconInContextMenu?: boolean;
  icon?: ReactElement | FC;
  placeholder?: ((context: ActionContext) => string) | string;
  selected?: (context: ActionContext) => boolean;
  visible?: (context: ActionContext) => boolean;
  perform?: (context: ActionContext) => void;
  children?: ((context: ActionContext) => Action[]) | Action[];
  isBeta?: boolean;
};

export type CommandBarAction = {
  id: string;
  name: string;
  section?: string;
  shortcut: string[];
  keywords: string;
  placeholder?: string;
  icon?: ReactElement;
  perform?: () => void;
  children?: string[];
  parent?: string;
};

export type LocationWithState = Location & {
  state: Record<string, string>;
};

export type Toast = {
  id: string;
  createdAt: string;
  message: ReactNode;
  type: "warning" | "error" | "info" | "success";
  timeout?: number;
  reoccurring?: number;
  alignTop?: boolean;
  action?: {
    text: string;
    onClick: MouseEventHandler<HTMLSpanElement>;
  };
};

export type FetchOptions = {
  prefetch?: boolean;
  revisionId?: string;
  shareId?: string;
  force?: boolean;
};

export type NavigationNode = {
  id: string;
  title: string;
  url: string;
  children: NavigationNode[];
  isDraft?: boolean;
  type?: DocumentType;
  icon?: string | null;
};

export type CollectionSort = {
  field: string;
  direction: "asc" | "desc";
};

// Pagination response in an API call
export type Pagination = {
  limit: number;
  nextPath: string;
  offset: number;
};

// Pagination request params
export type PaginationParams = {
  limit?: number;
  offset?: number;
  sort?: string;
  id?: string;
  direction?: "ASC" | "DESC";
  type?: DocumentType[];
  query?: string;
};

export type SearchResult = {
  id: string;
  ranking: number;
  context: string;
  document: Document;
};

export type ToastOptions = {
  type: "warning" | "error" | "info" | "success";
  timeout?: number;
  alignTop?: boolean;
  action?: {
    text: string;
    onClick: MouseEventHandler<HTMLSpanElement>;
  };
};

export type Align = "left" | "center" | "right";

export type WrapText = "wrap" | "none";

export type StyledWithVh = {
  vh: number;
}

export enum LockIconState {
  HIDDEN = "HIDDEN",
  LOCK = "LOCK",
  UNLOCK = "UNLOCK"
}
